import React from "react";
import EggDonation from "../components/EggDonation/EggDonation";

const EggDonationPage = () => {
  return (
    <div>
      <EggDonation />
    </div>
  );
};

export default EggDonationPage;
