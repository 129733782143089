import React from "react";
import Reviews from "../components/Reviews/Reviews";

const ReviewsPage = () => {
  return (
    <div>
      <Reviews />
    </div>
  );
};

export default ReviewsPage;
