import React from "react";
import OneProduct from "../components/OneProduct/OneProduct";

const OneProductPage = () => {
  return (
    <div>
      <OneProduct />
    </div>
  );
};

export default OneProductPage;
