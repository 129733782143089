import React from "react";
import News from "../components/News/News";

const NewsPage = () => {
  return (
    <div>
      <News />
    </div>
  );
};

export default NewsPage;
