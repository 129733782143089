// LangContextProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";

export const LangContext = createContext();
export const useLang = () => useContext(LangContext);

const LangContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    } else {
      // Если в localStorage нет значения "language", установить "en" по умолчанию
      localStorage.setItem("language", "en");
    }
  }, []);

  const changeLang = (newLang) => {
    setLanguage(newLang);
    localStorage.setItem("language", newLang);
  };
  const translationEgg = {
    en: {
      delivery: "Delivery Method",
      cesarean: "Cesarean Section",
      natural: "Natural Delivery",
      tunduk: "Having an account in the Tunduk app",
      donationForm: "Egg Donation Questionnaire",
      photo: "Your photo",
      example: "Example",
      motherForm: "Surrogate Mother Candidate Form",
      firstName: "First name",
      lastName: "Last name",
      dateOfBirth: "Date of birth",
      whatStage: "On which week did you give birth?",
      breastfed: "Have you breastfed?",
      whenBreastfed: "When did you breastfeed?",
      hormonalContraceptives: "Have you used hormonal contraceptives?",
      yes: "Yes",
      no: "No",
      dateOfMenstrualCycle: "Date of Menstrual Cycle",
      nationality: "Nationality",
      address: "Address",
      countryOfResidence: "Country of Residence",
      education: "Education",
      educationValues: [
        { name: "No education", value: "no_education" },
        {
          name: "Early childhood education",
          value: "early_childhood_education",
        },
        {
          name: "Primary eduation",
          value: "primary_education",
        },
        {
          name: "Lower secondary education",
          value: "lower_secondary_educatoin",
        },
        {
          name: "Upper secondary education",
          value: "upper_secondary_education",
        },
        {
          name: "Post secondary non tertiary education",
          value: "post-secondary_non-tertiary_education",
        },
        {
          name: "Short cycle tertiary eduation",
          value: "short-cycle_tertiary_education",
        },
        {
          name: "Bachelor's or equivalent level",
          value: "bachelor's_or_equivalent_level",
        },
        {
          name: "Master's or equivalent level",
          value: "master's_or_equivalent_level",
        },
        {
          name: "Doctoral or equivalent level",
          value: "doctoral_or_equivalent_level",
        },
        {
          name: "Not elsewhere classified",
          value: "not_elsewhere_classified",
        },
      ],
      currentJob: "Current job",
      maritalStatus: "Marital status",
      experienceOfDonation: "Experience of Donation",
      willingnessToTravel: "Willingness to travel",
      abortionTerms: "Abortion term",
      abortionQuanity: "Abortion quanity",
      lastAbortion: "Year of last abortion",
      surrogacyExperience: "Experience of participation in surrogacy programs?",
      doYouSmoke: "Do you smoke?",
      doYouDrink: "Do you drink alcohol?",
      chronicDiseases:
        "Do you have any chronic diseases? If so, what are they?",
      haveSurgeries: "Have you had any surgeries? Which ones and when?",
      famHistory: "Family history: (presence of diseases in relatives)?",
      multiplyPregnancy: "Do relatives have multiple pregnancies?",
      whySurrogacy: "Why do you want to become a surrogate mother?",
      whereYouKnow: "Where did you find out about the surrogacy program?",
      familyApproval:
        "Does your husband and other family members agree to participate in the program?",
      criminal:
        "Have you been subject to disciplinary, administrative or criminal liability?",
      physicalCharacteristics: "Physical Characteristics",
      bloodType: "Blood type, Rh factor",
      bloodTypeValues: [
        { value: "o_RhD_positive", name: "O(I) RhD positive" },
        { value: "a_RhD_positive", name: "A(II) RhD positive" },
        { value: "b_RhD_positive", name: "B(III) RhD positive" },
        { value: "ab_RhD_positive", name: "AB(IV) RhD positive" },
      ],
      height: "Height",
      weight: "Weight",
      howPregnancy: "How is your Pregnancy going?",
      bloodPresure: "What is your working blood pressure?",

      howMany: "Number of Children",
      name: "Name",
      eyeColor: "Eye color",
      hairColor: "Hair color",
      infoAboutChildren: "Information about children/family",
      numberOfChildren: "Number of children",
      howOftenMenstrualCycle: "Menstrual cycle, how often?",
      dateLastMenstrualCycle: "Date of the beginning of the last menstruation?",
      gynecologyDiseases: "Previous gynecological diseases?",
      sexyalydiseases: "Were there any sexually transmitted diseases?",
      abortionHave: "Have you had any miscarriages, abortions?",
      numberOfSiblings: "Number of Siblings",
      mothersHairColor: "Mother’s Hair Color",
      mothersEyeColor: "Mother’s Eye Color",
      fathersHairColor: "Father’s Hair Color",
      fathersEyeColor: "Father’s Eye Color",
      hobby: "Hobby",
      personalityType: "Personality type",
      personalityTypeValues: [
        { value: "sanguine", name: "Sanguine" },
        { value: "choleric", name: "Choleric" },
        { value: "phlegmatic", name: "Phlegmatic" },
        { value: "melancholic", name: "Melancholic" },
      ],
      healthStatus: "Health Status",
      managerName: "Manager name",
      facePhoto: "Face photo",
      sidePhoto: "Photo from the side",
      fullBodyPhoto: "Full body photo",
      passportPhoto: "Photo of an international passport?",
      contactData: "Contact Data",
      phoneNumber: "Phone number",
      phoneNumberWhatsApp: "Phone number,(Whatsapp)",
      email: "Email",
      comment: "Comment or Message",
      submitButton: "Submit",
      saveButton: "Save",
    },
    ru: {
      delivery: "Метод родоразрешения",
      cesarean: "Кесарево сечение",
      natural: "Естественный родоразрешение",
      tunduk: "Наличие аккаунта в приложении Тундук",
      donationForm: "Анкета на донорство яйцеклеток",
      photo: "Ваше фото",
      example: "Пример",
      abortionQuanity: "Количество абортов",
      motherForm: "Форма для кандидатки суррогатной матери",
      firstName: "Имя",
      lastName: "Фамилия",
      dateOfBirth: "Дата рождения",
      adвress: "Адрес",
      howPregnancy: "Как проходит ваша беременность?",
      bloodPresure: "Какое у вас артериальное давление?",
      children: "Дети",
      howMany: "Количество детей",
      breastfed: "Кормили ли вы грудью?",
      phoneNumberWhatsApp: "Номер телефона (WhatsApp)",
      managerName: "Имя менеджера",
      passportPhoto: "Фото международного паспорта?",
      dateOfMenstrualCycle: "Дата начала менструального цикла",
      nationality: "Национальность",
      whatStage: "На какой неделе вы родили?",
      countryOfResidence: "Страна проживания",
      education: "Образование",
      whenBreastfed: "Когда вы кормили грудью?",
      hormonalContraceptives: "Использовали ли вы гормональные контрацептивы?",
      yes: "Да",
      no: "Нет",
      address: "Адрес",
      abortionTerms: "Сроки аборта",
      abortionQuantity: "Количество абортов",
      lastAbortion: "Год последнего аборта",
      surrogacyExperience:
        "Есть ли опыт участия в программах суррогатного материнства?",
      doYouSmoke: "Вы курите?",

      doYouDrink: "Вы пьете алкоголь?",
      chronicDiseases: "Есть ли у вас хронические заболевания? Если да, какие?",
      haveSurgeries: "У вас были какие-либо операции? Какие и когда?",
      famHistory: "Семейный анамнез (присутствие заболеваний у родственников)?",
      multiplyPregnancy: "У родственников были многоплодные беременности?",
      whySurrogacy: "Почему вы хотите стать суррогатной матерью?",
      whereYouKnow: "Где вы узнали о программе суррогатного материнства?",
      familyApproval:
        "Ваш муж и другие члены семьи согласны участвовать в программе?",
      criminal:
        "Вас подвергали дисциплинарной, административной или уголовной ответственности?",
      educationValues: [
        { name: "Отсутствует образование", value: "no_education" },
        {
          name: "Образование раннего детства",
          value: "early_childhood_education",
        },
        { name: "Начальное образование", value: "primary_education" },
        {
          name: "Общее среднее образование (базовое)",
          value: "lower_secondary_education",
        },
        {
          name: "Общее среднее образование (полное)",
          value: "upper_secondary_education",
        },
        {
          name: "Среднее профессиональное образование (после средней школы)",
          value: "post-secondary_non-tertiary_education",
        },
        {
          name: "Образование бакалавра или эквивалентное",
          value: "bachelor's_or_equivalent_level",
        },
        {
          name: "Образование магистра или эквивалентное",
          value: "master's_or_equivalent_level",
        },
        {
          name: "Образование доктора или эквивалентное",
          value: "doctoral_or_equivalent_level",
        },
        {
          name: "Не классифицировано иначе",
          value: "not_elsewhere_classified",
        },
      ],
      currentJob: "Текущая работа",
      maritalStatus: "Семейное положение",
      experienceOfDonation: "Опыт донорства",
      willingnessToTravel: "Готовность к поездкам",
      physicalCharacteristics: "Физические характеристики",

      bloodType: "Группа крови, резус-фактор",
      bloodTypeValues: [
        {
          value: "o_RhD_positive",
          name: "Группа крови O(I) RhD положительная",
        },
        {
          value: "a_RhD_positive",
          name: "Группа крови A(II) RhD положительная",
        },
        {
          value: "b_RhD_positive",
          name: "Группа крови B(III) RhD положительная",
        },
        {
          value: "ab_RhD_positive",
          name: "Группа крови AB(IV) RhD положительная",
        },
      ],
      height: "Рост",
      weight: "Вес",
      eyeColor: "Цвет глаз",
      hairColor: "Цвет волос",
      infoAboutChildren: "Информация о детях и семье",
      numberOfChildren: "Количество детей",
      numberOfSiblings: "Количество братьев и сестер",
      mothersHairColor: "Цвет волос матери",
      mothersEyeColor: "Цвет глаз матери",
      fathersHairColor: "Цвет волос отца",
      fathersEyeColor: "Цвет глаз отца",
      hobby: "Хобби",
      name: "Имя",
      personalityType: "Тип личности",
      personalityTypeValues: [
        { value: "sanguine", name: "Сангвиник" },
        { value: "choleric", name: "Холерик" },
        { value: "phlegmatic", name: "Флегматик" },
        { value: "melancholic", name: "Меланхолик" },
      ],
      howOftenMenstrualCycle: "Как часто происходит менструальный цикл?",
      dateLastMenstrualCycle: "Дата начала последних месячных?",
      gynecologyDiseases: "Были ли у вас прежние гинекологические заболевания?",
      sexyalydiseases: "Были ли у вас половые инфекции?",
      abortionHave: "Происходили ли случаи выкидышей или абортов?",
      healthStatus: "Состояние здоровья",
      facePhoto: "Фото лица",
      sidePhoto: "Фото в профиль",
      fullBodyPhoto: "Фото всего тела",
      contactData: "Контактные данные",
      phoneNumber: "Номер телефона",
      email: "Email",
      comment: "Комментарий или сообщение",
      submitButton: "Отправить",
      saveButton: "Сохранить",
    },
    ch: {
      delivery: "分娩方式",
      cesarean: "剖腹产",
      natural: "自然分娩",
      tunduk: "拥有 Tunduk 应用程序账户",
      donationForm: "卵子捐赠调查表",
      photo: "您的照片",
      example: "示例",
      whatStage: "你在哪一周生产的？",
      motherForm: "代孕母亲候选人表格",
      firstName: "名字",
      lastName: "姓",
      abortionQuanity: "堕胎数量",
      dateOfBirth: "出生日期",
      address: "地址",
      howPregnancy: "您的怀孕进展如何？",
      bloodPresure: "您的工作血压是多少？",
      children: "孩子们",
      howMany: "子女数量",
      breastfed: "您有哺乳吗？",
      phoneNumberWhatsApp: "电话号码（WhatsApp）",
      managerName: "经理姓名",
      passportPhoto: "国际护照照片？",
      dateOfMenstrualCycle: "月经周期开始日期",
      nationality: "国籍",
      countryOfResidence: "居住国家",
      education: "教育",
      whenBreastfed: "母乳喂养的时间？",
      hormonalContraceptives: "您使用避孕药吗？",
      yes: "是",
      no: "否",
      abortionTerms: "流产时期",
      abortionQuantity: "流产次数",
      lastAbortion: "上次流产的年份",
      surrogacyExperience: "有代孕项目参与经验吗？",
      doYouSmoke: "您吸烟吗？",
      doYouDrink: "您喝酒吗？",
      chronicDiseases: "您患有慢性疾病吗？如果是的话，是什么疾病？",
      haveSurgeries: "您接受过手术吗？是哪种手术，什么时候接受的？",
      famHistory: "家族史：（亲属是否患有疾病）？",
      multiplyPregnancy: "亲属是否有多胞胎怀孕？",
      whySurrogacy: "为什么您想成为代孕母亲？",
      whereYouKnow: "您从哪里了解代孕项目？",
      familyApproval: "您的丈夫和其他家庭成员是否同意参加项目？",
      criminal: "您是否受到过纪律、行政或刑事责任？",
      educationValues: [
        { name: "没有受过教育", value: "no_education" },
        { name: "学前教育", value: "early_childhood_education" },
        { name: "初等教育", value: "primary_education" },
        { name: "普通中等教育（初中）", value: "lower_secondary_education" },
        { name: "普通中等教育（高中）", value: "upper_secondary_education" },
        {
          name: "高等非本科教育",
          value: "post-secondary_non-tertiary_education",
        },
        { name: "学士或相当水平", value: "bachelor's_or_equivalent_level" },
        { name: "硕士或相当水平", value: "master's_or_equivalent_level" },
        { name: "博士或相当水平", value: "doctoral_or_equivalent_level" },
        { name: "其他未分类", value: "not_elsewhere_classified" },
      ],
      howOftenMenstrualCycle: "月经周期有多频繁？",
      dateLastMenstrualCycle: "上次月经开始日期？",
      gynecologyDiseases: "以前是否患有妇科疾病？",
      sexyalydiseases: "曾经是否感染性传播疾病？",
      abortionHave: "曾有流产或堕胎的情况吗？",
      currentJob: "目前工作",
      maritalStatus: "婚姻状况",
      experienceOfDonation: "捐赠经验",
      willingnessToTravel: "是否愿意出差",
      physicalCharacteristics: "外貌特征",
      bloodType: "血型和Rh因子",
      bloodTypeValues: [
        { value: "o_RhD_positive", name: "O(I) RhD阳性" },
        { value: "a_RhD_positive", name: "A(II) RhD阳性" },
        { value: "b_RhD_positive", name: "B(III) RhD阳性" },
        { value: "ab_RhD_positive", name: "AB(IV) RhD阳性" },
      ],
      height: "身高",
      weight: "体重",
      eyeColor: "眼睛颜色",
      hairColor: "头发颜色",
      infoAboutChildren: "有关孩子/家庭的信息",
      numberOfChildren: "子女数量",
      numberOfSiblings: "兄弟姐妹数量",
      mothersHairColor: "母亲的头发颜色",
      mothersEyeColor: "母亲的眼睛颜色",
      fathersHairColor: "父亲的头发颜色",
      fathersEyeColor: "父亲的眼睛颜色",
      hobby: "爱好",
      personalityType: "个性类型",
      personalityTypeValues: [
        { value: "sanguine", name: "多愁善感" },
        { value: "choleric", name: "胆汁质" },
        { value: "phlegmatic", name: "粘液质" },
        { value: "melancholic", name: "抑郁质" },
      ],
      healthStatus: "健康状况",
      name: "名称",
      facePhoto: "正面照片",
      sidePhoto: "侧面照片",
      fullBodyPhoto: "全身照片",
      contactData: "联系方式",
      phoneNumber: "电话号码",
      email: "电子邮件",
      comment: "评论或留言",
      submitButton: "提交",
      saveButton: "保存",
    },
  };
  const news = {
    en: {
      newsMain: "News",
      search: "Search",
      readMore: "Read More",
      recentPost: "Recent Posts",
      recentText: "This Article’s Title goes Here, but not too long.",
      leaveComment: "Leave a comment...",
      prev: "Previous",
      next: "Next",
      comments: "Leave a comment...",
      empty: "Empty",
    },
    ru: {
      newsMain: "Новости",
      search: "Поиск",
      readMore: "Подробнее",
      recentPost: "Недавние публикации",
      recentText: "Эта статья находится здесь, но не долго.",
      leaveComment: "Оставьте комментарий...",
      prev: "Предыдущий",
      next: "Следующий",
      comments: "Оставить комментарий...",
      empty: "Пусто",
    },
    ch: {
      newsMain: "新闻",
      search: "搜索",
      readMore: "阅读更多",
      recentPost: "近期出版物",
      recentText: "这篇文章就在这里，但不会太久。",
      leaveComment: "发表评论...",
      prev: "上一页",
      next: "下一页",
      comments: "发表评论...",
      empty: "空",
    },
  };

  const contactUsElements = {
    en: {
      getInTouch: "Get in touch",
      contactUs: "Contact us",
      name: "Name",
      email: "Email",
      subject: "Subject",
      message: "Message",
      emergency: "Contacts",
      location: "Location",
      workingHours: "Working hours",
      submit: "Submit",
      workDay: "Mon-Sat",
      restDay: "Sunday Emergency only",
      address: "12 microdistrict, building 75/4",
      country: "Kyrgyzstan",
    },
    ru: {
      getInTouch: "Свяжитесь с нами",
      contactUs: "Напишите нам",
      name: "ФИО",
      email: "Электронная почта",
      subject: "Тема",
      message: "Сообщение",
      emergency: "Контакты",
      location: "Расположение",
      workingHours: "Рабочее время",
      submit: "Отправить",
      workDay: "Пн-Сб",
      restDay: "Воскресенье - только по срочным случаям",
      address: "12 мкр дом 75/4",
      country: "Kyrgyzstan",
    },
    ch: {
      getInTouch: "联系我们",
      contactUs: "写信给我们",
      name: "姓名",
      email: "电子邮件",
      subject: "主题",
      message: "留言",
      emergency: "联系方式",
      location: "地点",
      workingHours: "工作时间",
      submit: "发送",
      workDay: "周一至周六",
      restDay: "仅紧急事宜星期天 ",
      address: "",
      country: "吉尔吉斯斯坦",
    },
  };
  const carousel = {
    en: {
      allReview: "Read all reviews",
      leaveReview: "Leave a review",
      ourCustomers: "Our Customers",
      leaveAFeedback: "Leave a Feedback",
      ourCustomersFeedback: "Our Customers Feedback",
      trustOurCustomers: "Don’t take our word for it. Trust our customers",
      experience: "Describe your experience",
      post: "Post",
      cancel: "Cancel",
      empty: "No reviews",
    },
    ru: {
      allReview: "Читать все отзывы",
      leaveReview: "Оставить отзыв",
      ourCustomers: "Наши клиенты",
      leaveAFeedback: "Оставить отзыв",
      ourCustomersFeedback: "Отзывы наших клиентов",
      trustOurCustomers: "Не верьте нам на слово. Доверьтесь нашим клиентам",
      experience: "Опишите свой опыт",
      post: "Отправить",
      cancel: "Отмена",
      empty: "Нет отзывов",
    },
    ch: {
      allReview: "阅读所有评论",
      leaveReview: "发表评论",
      ourCustomers: "我们的客户",
      leaveAFeedback: "发表反馈意见",
      ourCustomersFeedback: "客户反馈",
      trustOurCustomers: "不要相信我们的话。请相信我们的客户",
      experience: "描述您的经历",
      post: "发送",
      cancel: "取消",
      empty: "无评论",
    },
  };
  const profile = {
    en: {
      appForm: "My app forms",
      donors: "Donors",
      welcome: "Welcome",
    },
    ru: {
      appForm: "Мои прикладные формы",
      donors: "Доноры",
      welcome: "Добро пожаловать",
    },
    ch: {
      appForm: "我的应用程序表格",
      donors: "捐赠者",
      welcome: "欢迎",
    },
  };
  const authElement = {
    en: {
      welcomeBack: "Welcome back!",
      welcome: "Welcome!",
      signUpToContinue: "Please sign up to continue.",
      email: "Enter your email",
      password: "Enter password",
      rePassword: "Repeat password",
      privacyPolicy: "I agree with the privacy policy",
      haveAccount: "Already have an account or our card?",
      signInToContinue: "Please sign in to continue.",
      signIn: "Sign in",
      dontHaveAccount: "Don't have an account?",
      signUp: "Sign up",
    },
    ru: {
      welcomeBack: "С возвращением!",
      welcome: "Добро пожаловать!",
      signUpToContinue: "Пожалуйста, зарегистрируйтесь, чтобы продолжить.",
      email: "Введите свой e-mail",
      password: "Введите пароль",
      rePassword: "Повторите пароль",
      privacyPolicy: "Я согласен с политикой конфиденциальности",
      haveAccount: "У вас уже есть аккаунт?",
      signInToContinue: "Пожалуйста, войдите в систему, чтобы продолжить.",
      signIn: "Войти",
      dontHaveAccount: "У вас нет учетной записи?",
      signUp: "Зарегистрироваться",
    },
    ch: {
      welcomeBack: "欢迎回来",
      welcome: "欢迎光临",
      signUpToContinue: "请注册后继续。",
      email: "输入您的电子邮件地址",
      password: "输入密码",
      rePassword: "重复密码",
      privacyPolicy: "我同意隐私政策",
      haveAccount: "您已经有账户了吗？",
      signInToContinue: "请登录以继续。",
      signIn: "登录",
      dontHaveAccount: "你没有账户？",
      signUp: "注册",
    },
  };

  const myFormLang = {
    en: {
      tunduk: "Having an account in the Tunduk app",
      yoDonorAppForm: "Your Donor Application form",
      youSurrogacyApp: "Your Surrogacy Application form",
      physicalCharacteristics: "Physical Characteristics",
      childrenFam: "Children/family",
      firstName: "First Name",
      lastName: "Last Name",
      dateOfBirth: "Date of birth",
      nationality: "Nationality",
      country: "Country of Residence",
      address: "Residential address",
      education: "Education",
      currentJob: "Current job",
      maritalStatus: "Marital Status",
      expOfDonation: "Experience of Donation",
      willingnessToTravel: "Willingness to Travel",
      weight: "Weight",
      height: "Height",
      bloodType: "Blood type",
      numberOfChildren: "Number of Children",
      numberOfSiblings: "Number of Siblings",
      mothersHairColor: "Mother’s Hair color",
      mothersEyeColor: "Mother’s Eye color",
      fathersHairColor: "Father’s Hair color",
      fathersEyeColor: "Father’s Eye color",
      hobby: "Hobby",
      personalityType: "Personality type",
      healthStatus: "Health Status",
      facePhoto: "Photo of the face",
      fullBodyPhoto: "Photo of the full body",
      phoneNumber: "Phone Number",
      email: "Email",
      comments: "Comment or Message",
      famStatus: "Family status",
      howPregnancy: "How is your pregnancy going?",
      bloodPresure: "What is your working blood pressure",
      children: "Children",
      howMany: "How many",
      name: "Name",
      whatStage: "At what stage did you give birth?",
      breastfedrecentlyAndWhen:
        "Have you breastfed recently, how long ago did you finish lactation, if your children are younger?",
      hormonalContraceptives: "Have you used hormonal contraceptives?",
      menstrualCycle: "Menstrual cycle, how often?",
      dateLastMenstrualCycle: "Date of the beginning of the last menstruation",
      prevGynecologyDiseases: "Previous gynecological diseases",
      transmittedDisseases: "Were there any sexually transmitted diseases?",
      surrogacyExperience:
        "Experience of participation in surrogacy proggrams?",
      doYouSmoke: "Do you smoke?",
      doYouDrink: "Do you drink alcohol?",
      surgeries: "Have you had an surgeries? Which ones and when?",
      famHistory: "Family history (presence of diseases in relatives?)",
      multiplyPregnancy: "Do relatives have multiple pregnancies?",
      reasonSurrogacy: "Why do you want to become a surrogate mother?",
      whereYouKnow: "Where did you find out about the surrogacy program?",
      famAgree:
        "Does you husband and other family members agree to participate in the program?",
      criminal:
        "Have you been subject to disciplinary, administrative or criminal liability?",
      managerName: "Manager Name",
      passportPhoto: "Photo of an international passport",
      donor: "Donor",

      //
      specialCode: "Special Code",
      age: "Age",
      dateOfMenstrualCycle: "Date of Menstrual Cycle",
      sidePhoto: "Photo of the the side",
    },
    ru: {
      tunduk: "Наличие аккаунта в приложении Тундук",
      yoDonorAppForm: "Ваша анкета донора",
      youSurrogacyApp: "Ваша анкета на суррогатное материнство",
      physicalCharacteristics: "Физическая характеристика",
      childrenFam: "Дети/семья",
      firstName: "Имя",
      lastName: "Фамилия",
      dateOfBirth: "Дата рождения",
      nationality: "Национальность",
      country: "Страна проживания",
      address: "Адрес проживания",
      education: "Образование",
      currentJob: "Текущая работа",
      maritalStatus: "Семейное положение",
      expOfDonation: "Опыт донорства",
      willingnessToTravel: "Готовность путешествовать",
      weight: "Вес",
      height: "Высота",
      bloodType: "Группа крови",
      numberOfChildren: "Количество детей",
      numberOfSiblings: "Количество братьев и сестер",
      mothersHairColor: "Цвет волос матери",
      mothersEyeColor: "Цвет глаз матери",
      fathersHairColor: "Цвет волос отца",
      fathersEyeColor: "Цвет глаз отца",
      hobby: "Хобби",
      personalityType: "Тип личности",
      healthStatus: "Состояние здоровья",
      facePhoto: "Фотография лица",
      fullBodyPhoto: "Фотография всего тела",
      phoneNumber: "Номер телефона",
      email: "Электронная почта",
      comments: "Комментарий или сообщение",
      famStatus: "Семейное положение",
      howPregnancy: "Как проходит ваша беременность?",
      bloodPresure: "Какое у вас рабочее артериальное давление",
      children: "Дети",
      howMany: "Сколько",
      name: "Имя",
      whatStage: "На каком этапе вы родили ребенка?",
      breastfedrecentlyAndWhen:
        "Кормили ли вы грудью в последнее время, как давно закончили лактацию, если ваши дети младше?",
      hormonalContraceptives:
        "Пользовались ли вы гормональными контрацептивами?",
      menstrualCycle: "Менструальный цикл, как часто?",
      dateLastMenstrualCycle: "Дата начала последней менструации",
      prevGynecologyDiseases: "Предшествующие гинекологические заболевания",
      transmittedDisseases: "Были ли заболевания, передающиеся половым путем?",
      surrogacyExperience:
        "Опыт участия в программах суррогатного материнства?",
      doYouSmoke: "Вы курите?",
      doYouDrink: "Вы пьете алкоголь?",
      surgeries: "Были ли у вас операции? Какие и когда?",
      famHistory: "Семейный анамнез (наличие заболеваний у родственников?)",
      multiplyPregnancy: "Есть ли у родственников многоплодная беременность?",
      reasonSurrogacy: "Почему вы хотите стать суррогатной матерью?",
      whereYouKnow: "Где вы узнали о программе суррогатного материнства?",
      famAgree:
        "Согласны ли ваш муж и другие члены семьи участвовать в программе?",
      criminal:
        "Привлекались ли вы к дисциплинарной, административной или уголовной ответственности?",
      managerName: "Имя менеджера",
      passportPhoto: "Фотография заграничного паспорта",
      donor: "Донор",
      specialCode: "Специальный код",
      age: "Возраст",
      dateOfMenstrualCycle: "Дата менструального цикла",
      sidePhoto: "Фотография сбоку",
    },
    ch: {
      tunduk: "拥有 Tunduk 应用程序账户",
      yoDonorAppForm: "捐赠者申请表",
      youSurrogacyApp: "您的代孕申请表",
      physicalCharacteristics: "物理特征",
      childrenFam: "儿童/家庭",
      firstName: "姓名",
      lastName: "姓氏",
      dateOfBirth: "出生日期",
      nationality: "国籍",
      country: "居住国",
      address: "居住地址",
      education: "教育",
      currentJob: "当前工作",
      maritalStatus: "婚姻状况",
      expOfDonation: "捐赠体验",
      willingnessToTravel: "愿意出差",
      weight: "重量",
      height: "高度",
      bloodType: "血型",
      numberOfChildren: "儿童人数",
      numberOfSiblings: "兄弟姐妹数量",
      mothersHairColor: "母亲的发色",
      mothersEyeColor: "母亲的眼睛颜色",
      fathersHairColor: "父亲的头发颜色",
      fathersEyeColor: "父亲的眼睛颜色",
      hobby: "业余爱好",
      personalityType: "性格类型",
      healthStatus: "健康状况",
      facePhoto: "脸部照片",
      fullBodyPhoto: "全身照片",
      phoneNumber: "电话号码",
      email: "电子邮件",
      comments: "评论或留言",
      famStatus: "家庭状况",
      howPregnancy: "你的孕期进展如何？",
      bloodPresure: "您的工作血压是多少？",
      children: "儿童",
      howMany: "多少",
      name: "",
      whatStage: "您是在哪个阶段分娩的？",
      breastfedrecentlyAndWhen:
        "如果您的孩子年龄较小，您最近是否进行过母乳喂养？",
      hormonalContraceptives: "您使用过荷尔蒙避孕药吗？",
      menstrualCycle: "月经周期，多久一次？",
      dateLastMenstrualCycle: "最后一次月经开始的日期",
      prevGynecologyDiseases: "曾患妇科疾病",
      transmittedDisseases: "有没有性传播疾病？",
      surrogacyExperience: "参加代孕计划的经历？",
      doYouSmoke: "你吸烟吗？",
      doYouDrink: "你喝酒吗？",
      surgeries: "您做过手术吗？哪些手术？",
      famHistory: "家族史（亲属是否患有疾病？）",
      multiplyPregnancy: "您的亲戚是否多次怀孕？",
      reasonSurrogacy: "您为什么想成为代孕母亲？",
      whereYouKnow: "您是从哪里了解到代孕计划的？",
      famAgree: "您的丈夫和其他家庭成员是否同意参加该计划？",
      criminal: "您是否承担过纪律、行政或刑事责任？",
      managerName: "经理姓名",
      passportPhoto: "国际护照照片",
      donor: "捐赠者",
      specialCode: "特殊代码",
      age: "年龄",
      dateOfMenstrualCycle: "月经周期日期",
      sidePhoto: "侧面照片",
    },
  };

  const values = {
    changeLang,
    lang: language,
    translationsEgg: translationEgg[language],
    translationNews: news[language],
    contactUsElements: contactUsElements[language],
    carousel: carousel[language],
    profile: profile[language],
    authElement: authElement[language],
    myFormLang: myFormLang[language],
  };

  return <LangContext.Provider value={values}>{children}</LangContext.Provider>;
};

export default LangContextProvider;
